/*-----------------------------------------------------------------------------------
    CARD STYLE
-----------------------------------------------------------------------------------*/

.card {
    @include clearfix;
    background-color: #fff;
    margin-bottom: 1.6rem;
    height: 100%;

    @media only screen and (min-width: 768px) { 
        margin-bottom: 2.6rem;
    }

    @media only screen and (min-width: 992px) {
        display: flex;
        flex-direction: column;
    }

    /* &.btn__overlap {
        margin-bottom: -40px;
    
        .card__action {
            margin-bottom: -40px;
        }
    } */
}

.card__padding {
    padding: 1.6rem 3.6rem;
}
.card__padding__small {
    padding: 2.6rem;
}

.index-smartweb {
    .card {
        margin-bottom: 0;

        @media only screen and (min-width: 768px) { 
            margin-bottom: 1.6rem;
        }
    }
}

.card__image {
    min-height: 100px;
    background-color: #f5f7f9;
    &.border-tlr-radius {
        margin: 30px auto 0;
        border-radius: 50%;
        height: 100px;
        width: 100px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            width: 65%;

            path {
                fill: #69b0cf;
            }

        }

        img {
            position: relative;
            top: 3px;
            width: 105%;
            left: -2px;

            &.contrina,
            &.jackbot {
                top: 5px;
                width: 110%;
                left: -5px;
                max-width: 150%;
            }

            &.elektra {
                width: 60%;
                left: 24px;
                top: 15px;
            }
            &.jackbotqna {
                top: 8px;
                width: 97%;
                left: 2px;
            }
        }
    }
}

.card__image img {
    width: 100%;
    max-width: 100%;
    display: block;
}

.card__content {
    position: relative;
    flex: 1 0 auto;
    overflow: hidden;

    .p-small-card {
        font-size: 1em;
    }

    .card__article__descr {

         @media only screen and (min-width: 992px) { 
            position: absolute;
            z-index: 20;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            text-align: center;
            opacity: 0;
            background-color: rgba(0, 0, 0, 0.8);
            transition: all 0.5s ease-in-out;
            cursor: pointer;
        }

        .card__article__descr_cont {

            @media only screen and (min-width: 992px) { 
                position: absolute;
                left: 0;
                top: 50%;
                width: 100%;
                opacity: 0;
                transform: translateY(-100%);
                transition: all 0.5s ease-in-out;
            }
        }

        p {
            color: #333;
            padding: 0;
            font-weight: 400;
            margin-bottom: 1em;

            @media only screen and (min-width: 992px) { 
                padding: 0 20px;
            }

            &:last-child {
                @media only screen and (min-width: 992px) { 
                    margin-bottom: 0
                }
            }

        }
        span {
            color: #999;
        }
    }

    &:hover {
        .card__article__descr {
            opacity: 1;

            .card__article__descr_cont {
                @media only screen and (min-width: 992px) { 
                    opacity: 1;
                    transform: translateY(-50%);
                }
            }
        }
    }
}

.p-fact {
    margin-bottom: 0;
    font-size: 16px;
    line-height: 1.3;
    font-weight: 500;
    height: 100%;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.p-fact.p-fact-gray {
    color: #8d9091;
    margin-top: .5em;
    font-weight: 300;
}


/* card meta */

.card__meta {
    font-size: 1em;
    color: $brand-primary;
    letter-spacing: .2em;
    text-transform: uppercase;
    margin-bottom: 1.5em;
}


/* card action */

.card__action {
    overflow: hidden;
    padding-right: 3.6rem;
    padding-left: 3.6rem;
    padding-bottom: 2.6rem;
    @media only screen and (min-width: 992px) {
        //min-height: 216px;
    }
    a {
        width: 180px;
    }
}

.card__author {
    .card__author-content {
        display: inline-block;
        vertical-align: middle;
        margin-top: 10px;
    }
    span {
        display: block;
    }
    img {
        display: inline-block;
        vertical-align: middle;
        border-radius: 50%;
        margin-right: 0.6em;
        max-width: 100px;
    }
}


.padding-tb { padding-top: 1.6rem; padding-bottom: 1.6rem;}

.shadowDepth-no-shadow {
   box-shadow: none;   
}

.shadowDepth0 { box-shadow: 0 1px 3px rgba(0,0,0, 0.12); }

.shadowDepth1 {
   box-shadow: 0 0.6rem 4rem rgba(0, 0, 0, 0.08);   
}

.col-md-padding-50 {
    @media only screen and (min-width: 992px) {
        padding-right: 50px;
        padding-left: 50px;
    }
}




#angebot .card__content {
    @media only screen and (min-width: 992px) { 
        min-height: 260px;
    }
}

#angebot .card__content .card__article {
    h3 {
        span {            

            @media only screen and (min-width: 992px) { 
                display: block;
            }
        }
    }
    
    a {
        display: none;

        @media only screen and (min-width: 992px) { 
            display: block;
            position: absolute;
            bottom: 30px;
        }
      
    }
    
}

#angebot .card__content .card__article__descr {
    background:#FFF;
}


.box-shadow-blue {
    box-shadow: 0px 0px 40px 0px rgba(227, 237, 252, 1);
}

.flex-item {
    background: #FFF;
    display: flex;                 
    flex-direction: column;        
    justify-content: center;
    align-items: center; 
}


#leistungen {
    .card {
        margin: 1.5em 0;
    }
    .card__meta {
        margin-bottom: 0;
    }
    .card__content {
        h3 {
            text-transform: none;
            font-weight: 600;
        }
        p {
            font-size: 1em;
        }
    }
}