$sm-breackpoint: 769px;

/*-----------------------------------------------------------------------------------
    Index-Onlinekurs-Korrespondenz
-----------------------------------------------------------------------------------*/

.container {
    @media (min-width: 1300px) {
        width: 1280px;
    }
}

h1,
h2,
h3 {
    text-transform: uppercase;
    font-weight: 600;
}

.h1 {
    font-size: 2.5em;
    line-height: 110%;
    font-weight: 700;
    @media screen and (max-width: 767px) {
        font-size: 28px;
        line-height: 32px;
    }
}

h2 {
    font-weight: 600;
    font-size: 2em;
    line-height: 1.5;
    margin-bottom: 0.5em;
}

.h2--special {
    font-size: 3em;
    line-height: 1.5;
    font-weight: 300;
    text-transform: none;
}

h4 {
    font-weight: 400;
    font-size: 23px;
    line-height: 1.5;
    margin-bottom: 1em;
    text-transform: none;
}

p {
    font-size: 1.3125em;
    line-height: 170%;
}

strong {
    color: inherit;
    font-weight: 500;
}

ol,
ul {
    font-size: 1em;
    font-weight: 300;
    line-height: 1.75;
}

.btn-xl {
    background: linear-gradient(190.34deg, #e60048 18.05%, #a50034 94.36%);
    box-shadow: 4px 10px 20px rgba(165, 0, 52, 0.2);
}

.img-shadow {
    @include box-shadow();
}

.listCheck {
    li {
        font-size: 1.25em;
        line-height: 32px;
        font-weight: 300;
        @media screen and (max-width: 992px) {
            text-align: left;
        }

        &:before {
            top: 7px;
        }
    }
}

.row-eq-height {
    @media only screen and (min-width: 768px) {
        display: flex;
        flex-wrap: wrap;
    }
}

.alignCenter {
    align-items: center;
}

.col-12 {
    width: 100%;
    float: none;
}

/*-----------------------------------------------------------------------------------
    HERO
-----------------------------------------------------------------------------------*/
header.hero-img {
    overflow: hidden;
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 5em;
    padding-top: 150px;

    @media only screen and (min-width: 992px) {
        height: 100vh;
    }

    .row {
        display: flex;
        display: -webkit-flex;
        display: -ms-flex;
        > div {
            align-self: center;
        }
    }

    .hero-bg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        /* background: url('../img/hero-smart-illu-2.png') center center no-repeat; */
        background-size: cover;
        animation-name: zoom;
        animation-duration: 30s;
        animation-direction: alternate;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
        will-change: transform;

        &:after {
            content: "";
            background: linear-gradient(
                rgba(255, 255, 255, 0.7) 0%,
                rgba(255, 255, 255, 0.7) 50%,
                rgba(255, 255, 255, 0.9) 75%,
                rgba(255, 255, 255, 1) 100%
            );
            height: 100%;
            width: 100%;
            position: absolute;
            z-index: 1000;
            bottom: 0;
            display: block;
            top: auto;
        }
    }

    .intro-text {
        color: #000;
        text-align: left;
        @media screen and (max-width: 991px) {
            text-align: center;
        }

        .intro-heading {
            /* @include linear-gradient-text(); */
            font-weight: 700;
            font-size: 2em;

            @media only screen and (-ms-high-contrast: active),
                (-ms-high-contrast: none) {
                @include linear-gradient-hide();
            }

            @media only screen and (min-width: 768px) {
                font-size: 4em;
                margin: 0 auto;
                text-transform: uppercase;
            }

            @media only screen and (min-width: 992px) {
                font-size: 4em;
                margin: 0 auto;
                text-transform: uppercase;
            }
        }

        .intro-lead-in {
            font-size: 1.875em;
            max-width: 620px;
            text-transform: none;

            @media only screen and (min-width: 768px) {
                margin: 1.25em 0px;
                font-weight: 400;
                font-size: 1.5em;
                line-height: 1.3;
            }
        }

        h4.intro-lead-in {
            text-transform: uppercase;
        }

        .text-small {
            font-size: 1em;
            margin: 1em auto;
            letter-spacing: 0.8px;
            font-weight: 600;
        }
    }
}

.video-container {
    background: linear-gradient(
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0.75) 50%,
        rgba(255, 255, 255, 1) 100%
    );

    .title-container {
        bottom: initial;
        width: 100%;
        text-align: center;
        color: #fff;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .intro-text {
            color: #000;

            .intro-heading {
                font-weight: 700;

                @media only screen and (min-width: 992px) {
                    font-size: 8em;
                    margin: 0 auto;
                    text-transform: uppercase;
                }
            }

            .intro-lead-in {
                font-size: 1.5em;
                max-width: 850px;
                text-transform: none;

                @media only screen and (min-width: 768px) {
                    margin: 1.25em auto;
                    font-weight: 400;
                    font-size: 1.5em;
                    line-height: 1.3;
                }
            }

            h4.intro-lead-in {
                text-transform: uppercase;
            }

            .text-small {
                font-size: 1em;
                margin: 1em auto;
            }
        }
    }
}

@keyframes zoom {
    from {
        transform: scale(1);
    }

    to {
        transform: scale(1.3);
    }
}

.index-success {
    header.hero-img {
        height: auto;

        @media only screen and (min-width: 992px) {
            height: 100vh;
        }

        .intro-text {
            .intro-heading {
                @media only screen and (min-width: 992px) {
                    font-size: 6em;
                }
            }
        }
    }

    .verticalAlignedMiddle > div {
        flex-direction: column;
    }
}

/*-----------------------------------------------------------------------------------
    Shapes
-----------------------------------------------------------------------------------*/
.shape-one {
    position: absolute;
    left: -1%;
    top: 0;
    right: auto;
    max-width: 221px;
    z-index: -1;
}

.shape-two {
    position: absolute;
    right: -1%;
    top: -25%;
    left: auto;
    bottom: 0;
    z-index: -1;
}

.shape-three {
    position: absolute;
    left: -1%;
    top: 25%;
    right: auto;
    bottom: 0;
    max-width: 450px;
    z-index: -1;
    @media screen and (max-width: 480px) {
        max-width: 336px;
        top: 28%;
        left: -2%;
    }
}

.shape-four {
    position: absolute;
    right: -1%;
    top: -40%;
    left: auto;
    bottom: 0;
    max-width: 300px;
    z-index: -1;
}

.shape-one,
.shape-two,
.shape-three,
.shape-four {
    // display: none;

    // path {
    //     fill: #f5f5f5;
    // }
}

/*-----------------------------------------------------------------------------------
    ALL SECTIONS
-----------------------------------------------------------------------------------*/
#about,
#stile,
#vorlagen,
#vorteile {
    text-align: center;

    @media only screen and (min-width: 992px) {
        text-align: left;
    }

    img {
        margin: 0 auto;

        @media only screen and (min-width: 992px) {
            margin: initial;
        }
    }
}

/*-----------------------------------------------------------------------------------
    About
-----------------------------------------------------------------------------------*/
#about {
    img.img-center {
        margin: 0 auto;
    }
}

/*-----------------------------------------------------------------------------------
    Stile
-----------------------------------------------------------------------------------*/
#stile {
    .container {
        > div:last-child {
            display: flex;
            flex-wrap: wrap;

            > div:first-child {
                order: 2;

                @media only screen and (min-width: 992px) {
                    order: 1;
                }
            }

            > div:last-child {
                order: 1;

                @media only screen and (min-width: 992px) {
                    order: 2;
                }
            }
        }
    }
}

/*-----------------------------------------------------------------------------------
    Vorlagen
-----------------------------------------------------------------------------------*/
#vorlagen {
    .container {
        > div:last-child {
            display: flex;
            flex-wrap: wrap;

            > div:first-child {
                order: 2;
                margin: 1.5em auto;

                @media only screen and (min-width: 992px) {
                    order: 1;
                    margin: 0;
                }
            }

            > div:last-child {
                order: 1;

                @media only screen and (min-width: 992px) {
                    order: 2;
                }
            }
        }
        .row.row-eq-height.alignCenter.narrow-container {
            max-width: 900px;
            margin: 30px auto;
            @media screen and (max-width: 992px) {
                margin: 20px auto;
            }
        }
    }
    .customer-name {
        text-transform: uppercase;
        font-size: 19px;
        font-weight: 400;
    }
}

.video-poster-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    overflow: hidden;

    .video-poster {
        position: relative;
        float: left;
        overflow: hidden;
        width: 100%;
        height: auto;
        background: #fff;
        text-align: center;
        //background: linear-gradient(-45deg, #34495e 0%, #cc6055 100%);

        img {
            position: relative;
            display: block;
            min-height: 100%;
            max-width: 100%;
            opacity: 0.8;
            transition: opacity 0.35s, transform 0.35s;
            object-fit: cover;
        }

        figcaption {
            padding: 2em;
            color: #fff;
            text-transform: uppercase;
            font-size: 1.25em;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        p {
            position: absolute;
            bottom: 0;
            left: 0;
            height: calc(100% - 50px);
            margin: 20px;
            padding: 30px;
            border: 2px solid $gold;
            text-transform: none;
            font-size: 90%;
            color: #333;
            opacity: 0;
            transform: scale3d(0.8, 0.8, 1);
            transform-origin: 50% -100%;
            transition: opacity 0.35s, transform 0.35s;
        }
    }

    &:hover {
        .video-play-button {
            opacity: 0;
            transform: scale3d(0.8, 0.8, 1);
            transform-origin: 50% -100%;
        }

        .video-poster {
            img {
                opacity: 0.1;
                transform: scale3d(2, 2, 1);
            }

            figcaption {
                p {
                    opacity: 1;
                    transform: scale3d(1, 1, 1);
                }
            }
        }
    }
}

.video-poster-container {
    .circle {
        border-radius: 50%;
    }

    .player {
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -57px 0 0 -57px;
        height: 114px;
        width: 114px;
        background-color: #f2f0f0;
        cursor: pointer;
        transition: transform 0.3s ease-in-out;
        opacity: 1;
        transform: scale3d(1, 1, 1);
        transform-origin: 50% -100%;
        transition: opacity 0.35s, transform 0.35s;
    }

    .animated {
        /* Smoother animations */
        -webkit-transform: translateZ(0);
    }

    .player .triangle {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 0;
        height: 0;
        margin: -20px 0 0 -10px;
        border-top: 20px solid transparent;
        border-left: 30px solid $brand-primary;
        border-bottom: 20px solid transparent;
        transition: transform 0.3s ease-in-out;
    }

    .player:hover .triangle {
        border-top: 20px solid transparent;
        border-left: 30px solid $brand-primary;
        border-bottom: 20px solid transparent;
        transform: scale(1.1);
        transform-origin: center;
    }

    .player .buffering {
        position: absolute;
        top: 5px;
        left: 5px;
        width: 104px;
        height: 104px;
        border-right: 2px solid #d3d1d1;
        border-bottom: 2px solid #d3d1d1;
        border-left: 2px solid #f2f0f0;
        border-top: 2px solid #f2f0f0;
        animation: rotate 3s infinite linear;
    }

    &:hover {
        .player {
            opacity: 0;
            transform: scale3d(0.8, 0.8, 1);
        }
    }
}

// .confirm-text {
//     background-color: #fff;
//     width: 300px;
//     position: absolute;
//     z-index: 10;
//     left: 50%;
//     top: 50%;
//     padding: 20px;
//     transform: translate(-50%, -50%);
//     display: none;
// }

// &:hover {
//     .confirm-text {
//         display: block;
//     }
// }
// }

/* buffer */
@-webkit-keyframes rotate {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}

@-moz-keyframes rotate {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}

@-ms-keyframes rotate {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes rotate {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}

/*-----------------------------------------------------------------------------------
    Vorteile
-----------------------------------------------------------------------------------*/
.card {
    &.card__padding {
        padding: 1.25em;
    }

    .card__meta {
        margin-bottom: 0.25em;
    }

    h3 {
        margin-bottom: 0.125em;
        text-transform: uppercase !important;
        font-size: 22px !important;
        line-height: 37.4px;
    }
    .btn.btn-xl.top-buffer-20 {
        font-size: 16px;
    }
}

.card__image {
    min-height: 100px;
    background-color: transparent;

    &.image-icon {
        margin: 30px auto 0;
        height: 104px;
        width: 104px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            width: 100%;

            path {
                //fill: url(#gradient-horizontal);
            }
        }
    }
}

.vorteile {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;

    .card {
        width: 100%;
        max-width: 460px;
        background: none;

        @media only screen and (min-width: 768px) {
            margin-right: 2em;
            margin-left: 2em;
        }

        @media only screen and (min-width: 992px) {
            margin-right: 4em;
            margin-left: 4em;
        }

        h3 {
            text-transform: none;
        }
    }
}

/*-----------------------------------------------------------------------------------
    Angebot
-----------------------------------------------------------------------------------*/
.bottom-info {
    background: linear-gradient(194.06deg, #e60048 18.05%, #a50034 94.36%);
    box-shadow: 2px 5px 10px rgba(165, 0, 52, 0.1);

    @media only screen and (min-width: 992px) {
        display: flex;
        flex: wrap;
        align-items: center;
        justify-content: space-around;
        padding: 4em 5em 4em 4em;
    }

    .h3--offer {
        font-weight: 400;
        font-size: 2.25em;
        line-height: 1.3;
        margin: 0 0 1.25em 0;

        @media only screen and (min-width: 992px) {
            font-weight: 400;
            font-size: 2.75em;
            line-height: 1.5;
            text-align: left;
            flex: 0 1 66%;
            margin: 0;
        }
    }
}

/*-----------------------------------------------------------------------------------
    Kontakt
-----------------------------------------------------------------------------------*/
#kontakt {
    p {
        font-size: 1.25em;
        line-height: 1.75;
    }

    strong {
        font-weight: 700;
    }

    .hd-HeroCta-wrapper {
        padding-left: 10px;
        text-align: center;

        @media only screen and (min-width: 992px) {
            padding-left: 130px;
            text-align: left;
        }
    }

    .hd-HeroCta.content {
        strong {
            @media only screen and (min-width: 640px) {
                margin-right: 80px;
            }
        }
    }
}

/*-----------------------------------------------------------------------------------
    Footer
-----------------------------------------------------------------------------------*/

.index-success {
    footer {
        .container {
            .row {
                @media only screen and (min-width: 768px) {
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
}

/*-----------------------------------------------------------------------------------
    Scrollanimation Styles
-----------------------------------------------------------------------------------*/

.sm-cta-svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    svg {
        path {
            fill: rgba(105, 176, 207, 0.1);
        }
    }
}

.section {
    transition: opacity 0.6s ease-in-out, transform 0.6s ease-in-out;
    transform: matrix(1, 0, 0, 1, 0, 50);
    opacity: 0;

    @media only screen and (min-width: $sm-breackpoint) {
        padding: 70px 0;
    }

    > div {
        z-index: 1;
    }
}

.slideUpAnimated {
    transition: opacity 0.6s ease-in-out, transform 0.6s ease-in-out;
    transform: matrix(1, 0, 0, 1, 0, 50);
    opacity: 0;
}

.bg-shape {
    position: fixed;
    top: 50%;
    left: 0;
    width: 150%;
    height: 100%;
    background: url("../img/bg/background-bubble--left.svg") left top no-repeat;
    background-size: 50%;
    transform: translateY(-40%);
    z-index: -1;
}

.bg-shape-2 {
    position: fixed;
    top: 50%;
    right: 0;
    width: 120%;
    height: 100%;
    background: url("../img/bg/background-bubble--right.svg") right top
        no-repeat;
    background-size: 50%;
    transform: translateY(-40%);
    z-index: -1;
}

.sm-show {
    opacity: 1;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

.sm-hide {
    display: none;
}

.sm-opacity {
    transition: opacity 0.3s ease-in-out;
    opacity: 0;

    @media only screen and (min-width: $sm-breackpoint) {
    }
}

.sm-opacity-2 {
    transition: opacity 1s ease-in-out;
    opacity: 0;

    @media only screen and (min-width: $sm-breackpoint) {
    }
}

.sm-fade-in {
    opacity: 1;
}

.panel {
    &.panel-default {
        border: none;
        border-radius: 0;
        margin-bottom: 25px;
    }

    .panel-body {
        background: #fff;
        padding: 2em;

        p {
            font-size: 1.125em;
        }
    }
}

.panel .panel-heading {
    border-radius: 0;
    padding: 0;
}

.panel .panel-heading a {
    box-shadow: 0 0 40px 0 #e3edfc;
    display: block;
    background: #fff;
    color: #333;
    padding: 1.5em 4.5em 1.5em 1.5em;
    border-radius: 0;
    text-decoration: none;
    font-size: 20px;
    text-transform: none;
    font-weight: 500;

    &:before,
    &:after {
        width: 16px;
        height: 1px;
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        right: 10px;
        left: auto;
        margin-top: -1px;
        background-color: #a50034;
        transition: all 0.5s;

        @media only screen and (min-width: 480px) {
            width: 30px;
            top: 50%;
            right: 30px;
            height: 2px;
        }
    }

    &:after {
        width: 1px;
        height: 16px;
        right: 17px;
        margin-top: -8px;

        @media only screen and (min-width: 480px) {
            width: 2px;
            height: 30px;
            right: 45px;
            margin-top: -15px;
        }
    }

    &[aria-expanded="true"] {
        &:before,
        &:after {
            transform: rotate(-90deg);
        }

        &:before {
            opacity: 0;
        }
    }
}

@media screen and (max-width: 767px) {
    .customers-are-saying {
        .top-buffer-150.row {
            margin-top: 30px !important;
        }
    }
}



#testimonial {
    h2,
    .hd-quote {
        text-align: center;

        @media screen and (min-width: 767px) {
            text-align: left;
        }
    }

    img {
        margin: 0 auto;
        @media screen and (min-width: 767px) {
            margin: 0 auto;
        }
    }
}
.hd-quote__author {
    text-align: center;
    @media screen and (min-width: 767px) {
        text-align: left;
    }
    &:before {
        display: none;
        @media screen and (min-width: 767px) {
            display: block;
        }
    }
}
.hd-quote {
    @include body-font();
    line-height: 1.7;
    font-size: 2rem;
    font-weight: 300;

    @media only screen and (min-width: 1024px) {
        font-size: 2.5rem;
    }
}
