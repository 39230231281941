.hd-accordion {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
	position: relative;

    @media only screen and (min-width: 1024px) {
    	margin-left: auto;
    	margin-right: auto;
    	max-width: 960px;
    }
}

.hd-accordion__item {
    border-top: 1px solid #a50034;

    &:first-child {
    	border-top-color: transparent;
    }
}

.hd-accordion__toggle {
    width: 100%;
    min-height: 70px;
    display: block;
    position: relative;
    padding-left: 50px;
    border: none;
    background: #FFF;
    color: #a50034;
    font-family: freight-display-pro,serif;
    font-size: 1.2em;
    line-height: 1.5;
    text-align: left;
    cursor: pointer;
    outline: none!important;
    transition: .5s ease;

    @media only screen and (min-width:480px) {
        font-size: 2.5rem;
        min-height: 60px;
        padding-left: 100px;
    }

    &:before,
    &:after {
        width: 16px;
        height: 1px;
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 10px;
        margin-top: -1px;
        background-color: #a50034;
        transition: all .5s;

        @media only screen and (min-width:480px) { 
            width: 30px;
            top: 50%;
            left: 30px;
            height: 2px;
        }
    }

    &:after {
        width: 1px;
        height: 16px;
        left: 17px;
        margin-top: -8px;

        @media only screen and (min-width:480px) {
            width: 2px; 
            height: 30px;
            left: 45px;
            margin-top: -15px;
        }
    }
    .active & {
        margin: 5px 0;
        background-color: #fafafa;

        @media only screen and (min-width:480px) { 
            margin: 20px 0;
        }

        &:before, 
        &:after {
            transform: rotate(-90deg);
        }

        &:before {
            opacity: 0;
        }
    }
}
h3.hd-accordion__toggle {
    text-transform: none;
    font-weight: 300;
    display: flex;
    align-items: center;
    margin: 5px 0;

    @media only screen and (min-width:480px) { 
        margin: 20px 0;
    }
}

.hd-accordion__content {
    position: absolute;
    left: -9999px;
    overflow: hidden;
    padding: 0px 50px 20px 100px;
    line-height: 2;

    @media only screen and (min-width:320px) {
        padding:10px 25px 10px 50px;
    }

    @media only screen and (min-width:962px) { 
        padding:  0px 50px 20px 100px;
    }

    p {
    	font-size: 1.2em;
    }

    .open & {
        position: static;
    left: auto;
    }
}